import { extendTheme } from "@chakra-ui/react";
import colors from "./colors";
import Button from "./buttons";
import fonts from "./fonts";
import textStyles from "./text";
import styles from "./styles";
import customIcons from "./icons";
import Table from "./table";
import Heading from "./heading";

export const overrides = {
  styles,
  colors,
  textStyles,
  fonts,
  icons: {
    ...customIcons
  },

  components: {
    Button,
    Table,
    Heading

    // Other components go here
  }
};

export default extendTheme(overrides);
