import "@fontsource/poppins"; // Defaults to weight 400.

const text = {
  textStyles: {
    h1: {
      // you can also use responsive styles
      fontSize: ["48px", "72px"],
      fontWeight: "bold",
      lineHeight: "auto",
      fontFamily: "Poppins"
    },
    h2: {
      fontSize: ["36px", "48px"],
      fontWeight: "bold",
      lineHeight: "auto",
      fontFamily: "Poppins"
    },

    body: {
      fontFamily: "Poppins"
    }
  }
};

export default text;
