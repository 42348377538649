import "@fontsource/poppins/700.css";
const Heading = {
  baseStyle: {
    fontWeight: "700",
    fontSize: "22px",
    fontFamily: "Poppins"
  },
  // Two sizes: sm and md

  // Two variants: outline and solid
  variants: {},
  // The default size and variant values
  defaultProps: {}
};

export default Heading;
