import React from "react";
export function FullPageErrorFallback({
  error,
}: {
  error: { message: string };
}) {
  return (
    <div
      role="alert"
      style={{
        color: "#ef5350",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <p>Uh oh... There's a problem. Try refreshing the app.</p>
      <pre>{error.message}</pre>
    </div>
  );
}
