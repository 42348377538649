const Button = {
  // 2. We can add a new button size or extend existing
  sizes: {},
  // 3. We can add a new visual variant
  variants: {
    // 4. We can override existing variants
    solid: () => ({
      backgroundColor: "#53B5E0",
      color: "#FFFFFF",
      rounded: "7px"
    }),
    outline: () => ({
      backgroundColor: "#dcecf5",
      color: "#53B5E0",
      fontWeight: "700",
      border: "none",
      rounded: "7px"
    }),
    filled: () => ({
      backgroundColor: "#05103D",
      color: "#FFFFFF",
      rounded: "7px"
    })
  }
};

export default Button;
