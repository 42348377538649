import * as React from "react";
import { useAuth } from "./utils/auth-context";

const AuthenticatedApp = React.lazy(() =>
  import(/* webpackPrefetch: true*/ "./AuthenticatedApp")
);

const UnauthenticatedApp = React.lazy(() => import("./UnAuthenticatedApp"));

const ReviewerApp = React.lazy(() => import("./ReviewerApp"));

export const App = () => {
  const { data: user } = useAuth();

  return (
    <React.Suspense fallback={<div />}>
      {user ? (
        user.role === "admin" ||
        user.role === "owner" ||
        user.role === "superadmin" ||
        user.role === "manager" ||
        user.role === "support" ||
        user.role === "staff" ||
        (user.role === "reviewer" && user.permission_id !== null) ? (
          <AuthenticatedApp />
        ) : (
          <ReviewerApp />
        )
      ) : (
        <UnauthenticatedApp />
      )}
    </React.Suspense>
  );
};
