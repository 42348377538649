import "@fontsource/poppins"; // Defaults to weight 400.

const styles = {
  global: {
    // styles for the `body`
    body: {
      color: "#6A6B6C",
      backgroundColor: "#F7FAFB",
      fontSize: "16px"
    },
    // styles for the `a`
    a: {
      color: "teal.500",
      _hover: {
        textDecoration: "underline"
      }
    }
  }
};

export default styles;
