import React from "react";
import { InputGroup, Input, InputRightElement, HStack } from "@chakra-ui/react";
import { HiSearch } from "react-icons/hi";

type SearchInputProps = {
  searchTerm: string;
  onChange: (term: string) => void;
  onSearch: (val: string) => void;
  placeholder?: string;
  rightElement?: JSX.Element;
  width: string;
};

export const SearchInput = ({
  onSearch,
  onChange,
  placeholder,
  searchTerm,
  rightElement,
  width
}: SearchInputProps) => {
  return (
    <HStack width={width}>
      <InputGroup rounded={10} bg="#F7FAFB">
        <Input
          height={"40px"}
          type="text"
          placeholder={placeholder}
          fontSize={"13px"}
          _placeholder={{ color: "#BEBEBE", fontSize: "13px" }}
          paddingInlineStart={2}
          value={searchTerm}
          onKeyPress={e => {
            if (e.key === "Enter") {
              onSearch(searchTerm);
            }
          }}
          onChange={e => {
            onChange(e.target.value);
          }}
        />

        <InputRightElement
          height={"40px"}
          cursor="pointer"
          width={"50px"}
          bg={"#53B5E0"}
          _hover={{
            background: "#95c7e3e8"
          }}
          rounded={4}
          onClick={() => onSearch(searchTerm)}
          borderTopLeftRadius={"0px"}
          borderBottomLeftRadius={"0px"}
        >
          <HiSearch color="white" />
        </InputRightElement>
        {/* {rightElement ? (
          <InputRightElement
            bg="primary"
            color="black"
            cursor="pointer"
            onClick={() => onSearch(searchStr)}
          >
            {rightElement}
          </InputRightElement>
        ) : null} */}
      </InputGroup>
      {/* <Button onClick={() => onSearch(searchStr)}>Search</Button> */}
    </HStack>
  );
};
