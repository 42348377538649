import * as authApi from "./auth-api";

async function client(endpoint: string, httpConfig: any | undefined = {}) {
  const { data, token, headers: customHeaders, ...customConfig } = httpConfig;
  const config = {
    method: data ? "POST" : "GET",
    body: data ? JSON.stringify(data) : undefined,
    headers: {
      Authorization: token ? `Bearer ${token}` : undefined,
      "Content-Type": data ? "application/json" : undefined,
      ...customHeaders
    },
    ...customConfig
  };

  return window.fetch(`/api/${endpoint}`, config).then(async response => {
    if (response.status === 401) {
      await authApi.logout();
      // refresh the page for them
      // @ts-ignore
      // window.location.assign(window.location)
      return Promise.reject({ message: "Please re-authenticate." });
    }

    if (response.statusText === "No Content") {
      return null;
    }
    const data = await response.json();
    if (response.ok) {
      return data;
    } else {
      return Promise.reject(data);
    }
  });
}

export { client };
