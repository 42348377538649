const table = {
  // The parts of the component
  parts: ["table", "thead", "th", "tr", "td", "tbody"],
  // The base styles for each part
  baseStyle: {
    table: { borderRadius: "12px" },
    thead: {
      background: "#EDEDED",
      fontWeight: "700",
      borderRadius: "12px",
      color: "#6A6B6C",
      fontSize: "16px"
    },
    tbody: { color: "#05103D" },
    th: { textTransform: "none" }
  },
  tr: {
    borderRadius: "10px",

    fontWeight: "bold",
    border: "3px solid black"
  },
  td: { borderRadius: "10px", fontWeight: "700", fontSize: "16px" },
  // The size styles for each part
  sizes: {
    sm: {
      item: {
        fontSize: "5.75rem",
        px: 2,
        py: 1
      }
    }
  },
  // The variant styles for each part
  variants: {},
  // The default `size` or `variant` values
  defaultProps: {}
};

export default table;
