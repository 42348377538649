const colors = {
  primary: "#53B5E0",
  deepBlue: "#05103D",
  darkGray: "#6A6B6C",
  white: "#FFFFFF",
  lightGray: "#BEBEBE",
  faintBlue: "#F7FAFB",
  yellow: "#FFDA69",
  green: {
    50: "#d8f3dc",
    100: "#6FCF97"
  },
  red: {
    50: "#ffbdad",
    100: "#EB5757"
  }
};

export default colors;
