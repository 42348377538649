import React from "react";

const customIcons = {
  icon1: {
    path: <path fill="currentColor" d="assets\images\SVGs\paper 1.svg" />,
    // If the icon's viewBox is `0 0 24 24`, you can ignore `viewBox`
    viewBox: "0 0 40 40"
  },
  icon2: {
    path: (
      <g fill="currentColor">
        <path d="assets\images\SVGs\dashboard 1.svg" />
      </g>
    )
  }
};

export default customIcons;
